const Cookies = require("js-cookie");
export const storageSet = ( key, value) => {
    localStorage.setItem(key, JSON.stringify(value) || "");
};

export const storageGet = ( key) => {
    const data = localStorage.getItem(key) || "";
    if (!data) {
        return null;
    }
    return JSON.parse(data);
};
export const storageRemove = ( key) => {
    localStorage.removeItem(key);
};

export const saveTokens = (access_token, refresh_token, id_token, expires) => {
    // localStorage.setItem("access_token", JSON.stringify(access_token));
    // localStorage.setItem("refresh_token", JSON.stringify(refresh_token));
    // localStorage.setItem("id_token", JSON.stringify(id_token));
    Cookies.set("access_token", access_token, {expires: access_token / expires}); // Срок действия в часах
    Cookies.set("refresh_token", refresh_token);
    Cookies.set("id_token", id_token);
};

export const destroyTokens = () => {
    // localStorage.removeItem("access_token");
    // localStorage.removeItem("refresh_token");
    // localStorage.removeItem("id_token");
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    Cookies.remove("id_token");
};

export const getToken = (tokenName) => {
    // const token = localStorage.getItem(tokenName) || "";
    const token = Cookies.get(tokenName) || "";
    if (!token) {
        return "";
    }
    return token;
};
