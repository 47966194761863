import {createAsyncThunk} from "@reduxjs/toolkit";
import {authUrl, axiosInstance} from "../../../config/api";
import {destroyTokens, saveTokens, storageSet} from "../../../config/storage";
import {toast} from "react-toastify";

export const signUp = createAsyncThunk(
    "api/signup",
    async (body, thunkAPI) => {
        console.log("body", body);
        try {
            const response = await axiosInstance.post(authUrl + "/api/signup", body);

            toast.success(response?.data?.message);

            return response.data;
        } catch (error) {
            toast.error(error?.response?.data?.message);
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const login = createAsyncThunk(
    "/api/signin",
    async (body, thunkAPI) => {
        try {
            const response = await axiosInstance.post(authUrl + "/api/signin", {
                email: body?.email,
                password: body?.password,
            });
            if (response.status === 200) {
                toast.success("Login Successful");
                saveTokens(response?.data?.message?.AccessToken, response?.data?.message?.RefreshToken, response?.data?.message?.IdToken, response?.data?.message?.ExpiresIn);
                // storageSet("tokensData", response?.data?.message);// storageLogin(response);
                setTimeout(() => {
                    window.location.href = "/";
                }, 500);
            }

            return response.data;
        } catch (error) {
            toast.error(error?.response?.data?.message);
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const logout = createAsyncThunk("auth/logout", async () => {
    destroyTokens();
});

export const sendReset = createAsyncThunk(
    "/api/send/reset",
    async (body, thunkAPI) => {
        try {
            const response = await axiosInstance.post(authUrl + "/api/send/reset", body);

            toast.success(response?.data?.message);

            return response.data;
        } catch (error) {
            toast.error(error?.response?.data?.message);
            return thunkAPI.rejectWithValue(error);
        }
    },
);
export const resetPassword = createAsyncThunk(
    "/api/reset",
    async (body, thunkAPI) => {
        try {
            console.log("body", body);
            const response = await axiosInstance.post(authUrl + "/api/reset", body);

            toast.success(response?.data?.message);

            setTimeout(() => {
                window.location.href = "/";
            }, 500);

            return response.data;
        } catch (error) {
            toast.error(error?.response?.data?.message);
            return thunkAPI.rejectWithValue(error);
        }
    },
);

