import {createSlice} from "@reduxjs/toolkit";
import {login, resetPassword, sendReset, signUp} from "./auth.actions";
import {destroyTokens, storageGet} from "../../../config/storage";

const initialState = {
    tokensData: storageGet("tokensData"),
    isLoading: false,
    auth:  !!storageGet("tokensData"),
};

export const userSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(resetPassword.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(resetPassword.fulfilled, (state, ) => {
                state.isLoading = false;
            })
            .addCase(resetPassword.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(sendReset.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(sendReset.fulfilled, (state, ) => {
                state.isLoading = false;
            })
            .addCase(sendReset.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(signUp.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(signUp.fulfilled, (state, ) => {
                state.isLoading = false;
            })
            .addCase(signUp.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(login.pending, (state) => {
                state.isLoading = true;
                state.auth = false;
                state.tokensData = null;
                // storageRemove("tokensData");
            })
            .addCase(login.fulfilled, (state, {payload}) => {
                state.tokensData = payload?.message;
                state.isLoading = false;
                state.user = {user: payload.result};
                state.auth = true;
            })
            .addCase(login.rejected, (state) => {
                state.isLoading = false;
                state.tokensData = null;
                state.auth = false;
                destroyTokens();
                // storageRemove("tokensData");
            });
    },
});

export default userSlice.reducer;
