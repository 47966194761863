import {createSlice} from "@reduxjs/toolkit";
import {
    getWellArchitectedContent,
    getWellArchitectedList,
    getWellArchitectedDates,
    sendWellArchitectedReports,
    getWellArchitectedItemDetailes,
} from "./wellArchitected.actions";

export const wellArchitectedSlice = createSlice({
    name: "wellArchitected",
    initialState: {
        isLoading: false,
        data: [],
        list: [],
        dates: [],
        isSendingReport: false,
        detailedData: [],
        isLoadingDetailes: false,
    },
    reducers: {
        getArchitectedData: (state, action) => {
            // let {newDataObj} = action.payload;
            state.data = action.payload;
        },
        getArchitectedList: (state, action) => {
            // let {newDataObj} = action.payload;
            state.list = action.payload;
        },
        getArchitectedDates: (state, action) => {
            state.dates = action.payload;
        },
    },
    extraReducers: builder => {
        // getWellArchitectedList
        builder
            .addCase(getWellArchitectedContent.pending, state => {
                state.isLoading = true;
            })
            .addCase(getWellArchitectedContent.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isLoading = false;
            })
            .addCase(getWellArchitectedContent.rejected, state => {
                state.isLoading = false;
            })
            .addCase(getWellArchitectedList.fulfilled, (state, action) => {
                state.list = action.payload;
            })
            .addCase(getWellArchitectedDates.fulfilled, (state, action) => {
                state.dates = action.payload;
            })
            .addCase(getWellArchitectedDates.rejected, state => {
                state.dates = [];
                state.isLoading = false;
            })
            .addCase(sendWellArchitectedReports.pending, state => {
                state.isSendingReport = true;
            })
            .addCase(sendWellArchitectedReports.fulfilled, (state) => {
                state.isSendingReport = false;
            })
            .addCase(sendWellArchitectedReports.rejected, state => {
                state.isSendingReport = false;
            })
            .addCase(getWellArchitectedItemDetailes.pending, state => {
                state.isLoadingDetailes = true;
                state.detailedData = [];
            })
            .addCase(getWellArchitectedItemDetailes.fulfilled, (state, action) => {
                state.isLoadingDetailes = false;
                state.detailedData = action.payload;
            })
            .addCase(getWellArchitectedItemDetailes.rejected, state => {
                state.isLoadingDetailes = false;
                state.detailedData = [];
            });

    },
});

export const {getArchitectedData, getArchitectedList, getArchitectedDates} =
    wellArchitectedSlice.actions;

export default wellArchitectedSlice.reducer;
