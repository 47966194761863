import {
    Route,
    BrowserRouter as Router,
    Routes,
    Navigate,
} from "react-router-dom";
import {lazy, useEffect} from "react";
import initializeApp from "./app/init";
import "./App.css";
import {toast, ToastContainer} from "react-toastify";
import "./assets/styles/globals.css";
import {themeChange} from "theme-change";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import checkAuth from "./routes/checkAuth";
import {useDispatch, useSelector} from "react-redux";
import {verifyAccount} from "./features/accounts/api/account.actions";

const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Register = lazy(() => import("./pages/Register"));
const Reset = lazy(() => import("./pages/Reset"));
const LandingPage = lazy(() => import("./pages/LandingPage"));

const token = checkAuth();

initializeApp();

function App() {
    const {newAccount} = useSelector(state => state.accounts);
    const dispatch = useDispatch();

    useEffect(() => {
        let intervalId;

        if (newAccount) {
            intervalId = setInterval(
                () => dispatch(verifyAccount(newAccount)),
                5000
            );
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [newAccount]);

    useEffect(() => {
        themeChange(false);
    }, []);

    return (
        <>
            <Router>
                <Routes>
                    <Route
                        path="/"
                        element={
                            token ? (
                                <Navigate to="/app/dashboard" replace />
                            ) : (
                                <LandingPage />
                            )
                        }
                    />
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="/forgot-password"
                        element={<ForgotPassword />}
                    />
                    <Route path="/register" element={<Register />} />
                    <Route path="/reset_password" element={<Reset />} />
                    <Route path="/reset" element={<Reset />} />

                    <Route
                        path="/app/*"
                        element={
                            token ? <Layout /> : <Navigate to="/" replace />
                        }
                    />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </Router>
            <ToastContainer />
        </>
    );
}

export default App;
