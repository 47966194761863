import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {axiosInstance, baseUrlMain} from "../../config/api";

export const getLogoMain = createAsyncThunk("/logoMain/get", async () => {
    const response = await axiosInstance.post(
        baseUrlMain + "/api/logo/get",
        {}
    );
    console.log("Logo", response.data.message);
    return response.data.message;
});

export const getUserInfo = createAsyncThunk("/userInfo/get", async () => {
    const response = await axiosInstance.post(
        baseUrlMain + "/api/user",
        {}
    );
    console.log("User", response.data.message);
    return response.data.message;
});

export const userInfoSlice = createSlice({
    name: "userInfo",
    initialState: {
        isLoading: false,
        logoPath: "",
        user: {},
    },
    reducers: {
        getLogo: (state, action) => {
            // let {newDataObj} = action.payload;
            state.logoPath = action.payload;
        },
        getUser: (state, action) => {
            // let {newDataObj} = action.payload;
            state.user = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getLogoMain.pending, state => {
                state.isLoading = true;
            })
            .addCase(getLogoMain.fulfilled, (state, action) => {
                state.logoPath = action.payload;
                state.isLoading = false;
            })
            .addCase(getLogoMain.rejected, state => {
                state.isLoading = false;
            })
            .addCase(getUserInfo.pending, state => {
                state.isLoading = true;
            })
            .addCase(getUserInfo.fulfilled, (state, action) => {
                state.user = action.payload;
                state.isLoading = false;
            })
            .addCase(getUserInfo.rejected, state => {
                state.isLoading = false;
            });
    },
});

export const {getLogo} = userInfoSlice.actions;

export default userInfoSlice.reducer;
