import {createAsyncThunk} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import {baseUrlUser, axiosInstance} from "../../config/api";

export const getIviteUser = createAsyncThunk("api/add", async (body, thunkAPI) => {
    console.log("body", body);
    try {
        const response = await axiosInstance.post(
            baseUrlUser + "/api/add",
            body
        );
        console.log("!!!!!!!!!!!!! response  !!!!!!!!!!!", response);
        toast.success(response?.data?.message);

        return response.data;
    } catch (error) {
        console.log("error?.response", error?.response);
        toast.error(error?.response?.data?.message);
        return thunkAPI.rejectWithValue(error);
    }
});

export const getUsersContent = createAsyncThunk("api/get", async (body, thunkAPI) => {
    console.log("body", body);
    try {
        const response = await axiosInstance.post(
            baseUrlUser + "/api/get",
            body
        );
        toast.success(response?.data?.message);

        return response.data;
    } catch (error) {
        console.log("error?.response", error?.response);
        toast.error(error?.response?.data?.message);
        return thunkAPI.rejectWithValue(error);
    }
});

export const getRemoveUser = createAsyncThunk("api/remove", async (body, thunkAPI) => {
    console.log("body", body);
    try {
        const response = await axiosInstance.post(
            baseUrlUser + "/api/remove",
            body
        );
        toast.success(response?.data?.message);

        console.log("!!!!!!!!!!!!! response  !!!!!!!!!!!", response);

        return response.data;
    } catch (error) {
        console.log("error?.response", error?.response);
        toast.error(error?.response?.data?.message);
        return thunkAPI.rejectWithValue(error);
    }
});

export const getAllCompanies = createAsyncThunk("api/companies", async (body, thunkAPI) => {
    console.log("body", body);
    try {
        const response = await axiosInstance.post(
            baseUrlUser + "/api/companies",
            body
        );

        toast.success(response?.data?.message);

        return response.data;
    } catch (error) {
        console.log("error?.response", error?.response);
        toast.error(error?.response?.data?.message);
        return thunkAPI.rejectWithValue(error);
    }
});

export const getChangeCompany = createAsyncThunk("api/change/company", async (body, thunkAPI) => {
    console.log("body", body);
    try {
        const response = await axiosInstance.post(
            baseUrlUser + "/api/change/company",
            body
        );
        toast.success(response?.data?.message);

        console.log("!!!!!!!!!!!!! response  !!!!!!!!!!!", response);

        return response.data;
    } catch (error) {
        console.log("error?.response", error?.response);
        toast.error(error?.response?.data?.message);
        return thunkAPI.rejectWithValue(error);
    }
});

export const getChangeRole = createAsyncThunk("api/change/role", async (body, thunkAPI) => {
    console.log("body", body);
    try {
        const response = await axiosInstance.post(
            baseUrlUser + "/api/change/role",
            body
        );
        toast.success(response?.data?.message);

        console.log("!!!!!!!!!!!!! response  !!!!!!!!!!!", response);

        return response.data;
    } catch (error) {
        console.log("error?.response", error?.response);
        toast.error(error?.response?.data?.message);
        return thunkAPI.rejectWithValue(error);
    }
});

export const getNotification = createAsyncThunk("api/notifications", async (body, thunkAPI) => {
    console.log("body", body);
    try {
        const response = await axiosInstance.post(
            baseUrlUser + "/api/notifications",
            body
        );
        toast.success(response?.data?.message);

        console.log("!!!!!!!!!!!!! response  !!!!!!!!!!!", response);

        return response.data;
    } catch (error) {
        console.log("error?.response", error?.response);
        toast.error(error?.response?.data?.message);
        return thunkAPI.rejectWithValue(error);
    }
});

