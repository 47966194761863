import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {axiosInstance, baseUrlDashboard} from "../../config/api";
import {toast} from "react-toastify";

export const getDashboardData = createAsyncThunk("/api/forecast", async (body ,thunkAPI) => {
    try {
        const response = await axiosInstance.post(
            // baseUrlDashboard + "/api/chart",
            baseUrlDashboard + "/api/v2/chart",

            body
        );

        return response.data.message;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const getDashboardRemediation = createAsyncThunk("/api/remediation", async (body ,thunkAPI) => {
    try {
        const response = await axiosInstance.post(
            baseUrlDashboard + "/api/remediation",
            body
        );
        if (response.status === 200 && response.data.message) {
            toast.success(response.data.message);
        }

        return response.data.message;
    } catch (error) {
        toast.error(error?.response?.data?.message);
        return thunkAPI.rejectWithValue(error);
    }
});

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        isLoading: false,
        isRemediationLoading: false,
        data: [],
    },
    reducers: {
        addBillingData: (state, action) => {
            state.data = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getDashboardData.pending, state => {
                state.isLoading = true;
            })
            .addCase(getDashboardData.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isLoading = false;
            })
            .addCase(getDashboardData.rejected, state => {
                state.isLoading = false;
            })
            .addCase(getDashboardRemediation.pending, state => {
                state.isRemediationLoading = true;
            })
            .addCase(getDashboardRemediation.fulfilled, (state, action) => {
                state.isRemediationLoading = false;
            })
            .addCase(getDashboardRemediation.rejected, state => {
                state.isRemediationLoading = false;
            });
    },
});

export const {addBillingData} = dashboardSlice.actions;

export default dashboardSlice.reducer;
