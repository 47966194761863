import {createSlice} from "@reduxjs/toolkit";
import {
    addAccounts,
    changeCompany,
    changeLabel,
    getAccounts,
    getCompanies,
    getUrl,
    removeAccounts, verifyAccount,
} from "./account.actions";

export const accountsSlice = createSlice({
    name: "accounts",
    initialState: {
        isLoading: false,
        companies: [],
        accounts: [],
        urlData: null,
        newAccount: null,
    },
    reducers: {
        addNewAccount: (state, action) => {
            let payload = action.payload;
            state.newAccount = {...state.newAccount, ...payload};
        },
        clearNewAccount: (state) => {
            state.newAccount = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getAccounts.pending, state => {
                state.isLoading = true;
            })
            .addCase(getAccounts.fulfilled, (state, action) => {
                state.accounts = action.payload.message;
                state.isLoading = false;
            })
            .addCase(getAccounts.rejected, state => {
                state.isLoading = false;
            })
            .addCase(getCompanies.pending, state => {
                state.isLoading = true;
            })
            .addCase(getCompanies.fulfilled, (state, action) => {
                state.companies = action.payload.message;
                state.isLoading = false;
            })
            .addCase(getCompanies.rejected, state => {
                state.isLoading = false;
            })
            .addCase(removeAccounts.pending, state => {
                state.isLoading = true;
            })
            .addCase(removeAccounts.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(removeAccounts.rejected, state => {
                state.isLoading = false;
            })
            .addCase(changeLabel.pending, state => {
                state.isLoading = true;
            })
            .addCase(changeLabel.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(changeLabel.rejected, state => {
                state.isLoading = false;
            })
            .addCase(getUrl.pending, state => {
                state.isLoading = true;
            })
            .addCase(getUrl.fulfilled, (state, action) => {
                state.urlData = action.payload.message;
                state.isLoading = false;
            })
            .addCase(getUrl.rejected, state => {
                state.isLoading = false;
            })
            .addCase(changeCompany.pending, state => {
                // state.isLoading = true;
            })
            .addCase(changeCompany.fulfilled, (state) => {
                // state.isLoading = false;
            })
            .addCase(changeCompany.rejected, state => {
                // state.isLoading = false;
            })
            .addCase(verifyAccount.pending, state => {
                // state.isLoading = true;
            })
            .addCase(verifyAccount.fulfilled, (state, action) => {
                if (action?.payload?.message.includes("success")) {
                    state.newAccount = null;
                }
                // state.isLoading = false;
            })
            .addCase(verifyAccount.rejected, state => {
                // state.isLoading = false;
                state.newAccount = null;
            })
            .addCase(addAccounts.pending, state => {
                state.isLoading = true;
            })
            .addCase(addAccounts.fulfilled, (state, action) => {
                state.newAccount = {...state.newAccount, requestId: action?.payload?.message};
                state.isLoading = false;
            })
            .addCase(addAccounts.rejected, state => {
                state.isLoading = false;
                state.newAccount = null;
            });
    },
});

export const {addNewAccount, clearNewAccount} = accountsSlice.actions;

export default accountsSlice.reducer;
