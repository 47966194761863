import {getToken} from "../config/storage";

const checkAuth = () => {
    const TOKEN = getToken("access_token");
    const PUBLIC_ROUTES = [
        "login",
        "forgot-password",
        "register",
        "documentation",
        "reset",
        ""
    ];

    const isPublicPage = PUBLIC_ROUTES.some(r =>
        window.location.href.includes(r)
    );

    if (!TOKEN && !isPublicPage) {
        window.location.href = "/";
        return;
    }
    return !!TOKEN;
};

export default checkAuth;
