import {createAsyncThunk} from "@reduxjs/toolkit";
import {axiosInstance, baseUrlAi} from "../../../config/api";
import {toast} from "react-toastify";

export const sendMessage = createAsyncThunk(
    "/api/chat",
    async (body, thunkAPI) => {
        try {
            const response = await axiosInstance.post(baseUrlAi + "/api/chat", body);

            return response.data;
        } catch (error) {
            toast.error(error?.response?.data?.message);
            return thunkAPI.rejectWithValue(error);
        }
    },
);
