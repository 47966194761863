import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {axiosInstance, baseUrlBilling} from "../../config/api";
import {toast} from "react-toastify";

// export const getBillingData = createAsyncThunk("/forecast", async () => {
//     const response = await axios.get("/forecast", {});
//     return response.data;
// });

export const getBillingData = createAsyncThunk("/api/forecast", async (thunkAPI) => {
    try {
        const response = await axiosInstance.post(
            baseUrlBilling + "/api/forecast",
            {}
        );

        return response.data.message;
    } catch (error) {
        toast.error(error?.response?.data?.message);
        return thunkAPI.rejectWithValue(error);
    }
});

export const getSubscribeData = createAsyncThunk("/api/subscription", async (thunkAPI) => {
    try {
        const response = await axiosInstance.post(
            baseUrlBilling + "/api/subscription",
            {}
        );

        return response.data;
    } catch (error) {
        toast.error(error?.response?.data?.message);
        return thunkAPI.rejectWithValue(error);
    }
});

export const cancelSubscription = createAsyncThunk("/api/cancel", async (thunkAPI) => {
    try {
        const response = await axiosInstance.post(
            baseUrlBilling + "/api/cancel",
            {}
        );

        return response.data;
    } catch (error) {
        toast.error(error?.response?.data?.message);
        return thunkAPI.rejectWithValue(error);
    }
});



export const billingSlice = createSlice({
    name: "billing",
    initialState: {
        isLoading: false,
        data: [],
        isSubscribeLoading: false,
        subscribeData: [],
    },
    reducers: {
        addBillingData: (state, action) => {
            state.data = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getBillingData.pending, state => {
                state.isLoading = true;
            })
            .addCase(getBillingData.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isLoading = false;
            })
            .addCase(getBillingData.rejected, state => {
                state.isLoading = false;
            })
            .addCase(getSubscribeData.pending, state => {
                state.isSubscribeLoading = true;
            })
            .addCase(getSubscribeData.fulfilled, (state, action) => {
                state.subscribeData = action.payload;
                state.isSubscribeLoading = false;
            })
            .addCase(getSubscribeData.rejected, state => {
                state.isSubscribeLoading = false;
            })
            .addCase(cancelSubscription.pending, state => {
                state.isSubscribeLoading = true;
            })
            .addCase(cancelSubscription.fulfilled, (state) => {
                state.isSubscribeLoading = false;
            })
            .addCase(cancelSubscription.rejected, state => {
                state.isSubscribeLoading = false;
            });
    },
});

export const {addBillingData} = billingSlice.actions;

export default billingSlice.reducer;
