import {createSlice} from "@reduxjs/toolkit";
import {
    getUsersContent,
    getIviteUser,
    getRemoveUser,
    getChangeCompany,
    getAllCompanies,
    getChangeRole,
    getNotification,
} from "./users.actions";

export const usersSlice = createSlice({
    name: "users",
    initialState: {
        isLoading: false,
        isDataLoading: false,
        users: [],
        companies: [],
    },
    reducers: {
        addNewUser: (state, action) => {
            let {newUserObj} = action.payload;
            state.users = [...state.users, newUserObj];
        },
        deleteUser: (state, action) => {
            let {Email} = action.payload;
            state.users.splice(Email, 1);
        },
    },
    extraReducers: builder => {
        builder
            // ----------add user----------
            .addCase(getIviteUser.pending, state => {
                state.isLoading = true;
            })
            .addCase(getIviteUser.fulfilled, state => {
                state.isLoading = false;
            })
            .addCase(getIviteUser.rejected, state => {
                state.isLoading = false;
            })

            // ----------all users----------
            .addCase(getUsersContent.pending, state => {
                state.isDataLoading = true;
            })
            .addCase(getUsersContent.fulfilled, (state, action) => {
                state.users = action.payload.message;
                state.isDataLoading = false;
            })
            .addCase(getUsersContent.rejected, state => {
                state.isDataLoading = false;
            })

            // ----------remove user----------
            .addCase(getRemoveUser.pending, state => {
                state.isLoading = true;
            })
            .addCase(getRemoveUser.fulfilled, state => {
                state.isLoading = false;
            })
            .addCase(getRemoveUser.rejected, state => {
                state.isLoading = false;
            })

            // ----------change user company----------
            .addCase(getChangeCompany.pending, state => {
                state.isLoading = true;
            })
            .addCase(getChangeCompany.fulfilled, state => {
                state.isLoading = false;
            })
            .addCase(getChangeCompany.rejected, state => {
                state.isLoading = false;
            })

            // ----------all companies----------
            .addCase(getAllCompanies.pending, state => {
                state.isLoading = false;
            })
            .addCase(getAllCompanies.fulfilled, (state, action) => {
                state.companies = action.payload.message;
                state.isLoading = false;
            })
            .addCase(getAllCompanies.rejected, state => {
                state.isLoading = false;
            })

            // ----------change user role----------
            .addCase(getChangeRole.pending, state => {
                state.isLoading = false;
            })
            .addCase(getChangeRole.fulfilled, state => {
                state.isLoading = false;
            })
            .addCase(getChangeRole.rejected, state => {
                state.isLoading = false;
            })

            // ----------change user notification----------
            .addCase(getNotification.pending, state => {
                state.isLoading = false;
            })
            .addCase(getNotification.fulfilled, state => {
                state.isLoading = false;
            })
            .addCase(getNotification.rejected, state => {
                state.isLoading = false;
            });
    },
});

export const {addNewUser, deleteUser} = usersSlice.actions;

export default usersSlice.reducer;
