import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {getCompanies, getChangeDescription, getNewGroup, getRemoveGroup, getLogoUpdate} from "./groups.actions";

export const getGroupsContent = createAsyncThunk("/leads/content", async () => {
    const response = await axios.get("/api/users?page=2", {});
    return response.data;
});

export const groupsSlice = createSlice({
    name: "groups",
    initialState: {
        isLoading: false,
        groups: [],
    },
    reducers: {
        addNewGroup: (state, action) => {
            let {newGroupObj} = action.payload;
            state.groups = [...state.groups, newGroupObj];
        },
        deleteGroup: (state, action) => {
            let {index} = action.payload;
            state.groups.splice(index, 1);
        },
    },
    extraReducers: builder => {
        builder
            // ---------- get companies ----------
            .addCase(getCompanies.pending, state => {
                state.isLoading = true;
            })
            .addCase(getCompanies.fulfilled, (state, action) => {
                state.groups = action.payload.message;
                state.isLoading = false;
            })
            .addCase(getCompanies.rejected, state => {
                state.isLoading = false;
            })

            // ---------- change description ----------
            .addCase(getChangeDescription.pending, state => {
                state.isLoading = true;
            })
            .addCase(getChangeDescription.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(getChangeDescription.rejected, state => {
                state.isLoading = false;
            })

            // ---------- new group ----------
            .addCase(getNewGroup.pending, state => {
                state.isLoading = true;
            })
            .addCase(getNewGroup.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(getNewGroup.rejected, state => {
                state.isLoading = false;
            })

            // ---------- remove group ----------
            .addCase(getRemoveGroup.pending, state => {
                state.isLoading = true;
            })
            .addCase(getRemoveGroup.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(getRemoveGroup.rejected, state => {
                state.isLoading = false;
            })

            // ---------- logo update ----------
            .addCase(getLogoUpdate.pending, state => {
                state.isLoading = true;
            })
            .addCase(getLogoUpdate.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(getLogoUpdate.rejected, state => {
                state.isLoading = false;
            });
    },
});

export const {addNewGroup, deleteGroup} = groupsSlice.actions;

export default groupsSlice.reducer;
