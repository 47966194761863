import {createAsyncThunk} from "@reduxjs/toolkit";
import {axiosInstance, baseUrlAccount} from "../../../config/api";
import {toast} from "react-toastify";

export const getAccounts = createAsyncThunk(
    "api/get/accounts",
    async (body, thunkAPI) => {
        try {
            const response = await axiosInstance.post(baseUrlAccount + "/api/get", {});

            toast.success(response?.data?.message);

            return response.data;
        } catch (error) {
            toast.error(error?.response?.data?.message);
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const getCompanies = createAsyncThunk(
    "/api/companies/account",
    async (body, thunkAPI) => {
        try {
            const response = await axiosInstance.post(baseUrlAccount + "/api/companies", {});

            toast.success(response?.data?.message);

            return response.data;
        } catch (error) {
            toast.error(error?.response?.data?.message);
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const addAccounts = createAsyncThunk(
    "api/add/accounts",
    async (body, thunkAPI) => {
        try {
            const response = await axiosInstance.post(baseUrlAccount + "/api/add", body);

            toast.success("Your request is pending. Please wait...");

            return response.data;
        } catch (error) {
            toast.error(error?.response?.data?.message);
            return thunkAPI.rejectWithValue(error);
        }
    },
);
export const removeAccounts = createAsyncThunk(
    "api/remove/accounts",
    async (body, thunkAPI) => {
        try {
            const response = await axiosInstance.post(baseUrlAccount + "/api/remove", body);

            toast.success(response?.data?.message);

            return response.data;
        } catch (error) {
            console.log("!!!!!!!!!!!!!!!!!!! error", error);
            toast.error(error?.response?.data?.message);
            return thunkAPI.rejectWithValue(error);
        }
    },
);
export const changeLabel = createAsyncThunk(
    "api/label/accounts",
    async (body, thunkAPI) => {
        try {
            const response = await axiosInstance.post(baseUrlAccount + "/api/label", body);

            toast.success(response?.data?.message);

            return response.data;
        } catch (error) {
            console.log("!!!!!!!!!!!!!!!!!!! error", error);
            toast.error(error?.response?.data?.message);
            return thunkAPI.rejectWithValue(error);
        }
    },
);
export const getUrl = createAsyncThunk(
    "/api/url",
    async (body, thunkAPI) => {
        try {
            const response = await axiosInstance.post(baseUrlAccount + "/api/url", body);

            toast.success(response?.data?.message);

            return response.data;
        } catch (error) {
            console.log("!!!!!!!!!!!!!!!!!!! error", error);
            toast.error(error?.response?.data?.message);
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const changeCompany = createAsyncThunk(
    "api/company/account",
    async (body, thunkAPI) => {
        try {
            const response = await axiosInstance.post(baseUrlAccount + "/api/company", body);

            toast.success(response?.data?.message);

            return response.data;
        } catch (error) {
            console.log("!!!!!!!!!!!!!!!!!!! error", error);
            toast.error(error?.response?.data?.message);
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const verifyAccount = createAsyncThunk(
    "api/verify/account",
    async (body, thunkAPI) => {
        try {
            const response = await axiosInstance.post(baseUrlAccount + "/api/verify", body);

            if (response?.data?.message.includes("success")) {
                toast.success(response?.data?.message);
            } else {
                toast.info(response?.data?.message);
            }

            return response.data;
        } catch (error) {
            console.log("!!!!!!!!!!!!!!!!!!! error", error);
            toast.error(error?.response?.data?.message);
            return thunkAPI.rejectWithValue(error);
        }
    },
);
