import {createSlice} from "@reduxjs/toolkit";
import {sendMessage} from "./ai.actions";

export const aiSlice = createSlice({
    name: "ai",
    initialState: {
        isLoading: false,
        chat: [],
    },
    reducers: {
        addNewMessage: (state, action) => {
            let payload = action.payload;
            state.chat = [...state.chat, {from: "user", msg: payload.message}];
        },
    },
    extraReducers: builder => {
        builder
            .addCase(sendMessage.pending, state => {
                state.isLoading = true;
            })
            .addCase(sendMessage.fulfilled, (state, action) => {
                state.chat = [...state.chat, {from: "ai", msg: action.payload.message}];
                state.isLoading = false;
            })
            .addCase(sendMessage.rejected, state => {
                state.isLoading = false;
            });
    },
});

export const {addNewMessage} = aiSlice.actions;

export default aiSlice.reducer;
