import {createAsyncThunk} from "@reduxjs/toolkit";
// import {toast} from "react-toastify";
import {baseUrlDashboard, axiosInstance} from "../../config/api";
import {toast} from "react-toastify";

const constructPayload = body => {
    const {
        pillar,
        group = "All",
        account = "All",
        region = "All",
        service = "All",
        date = "",
        severityindex = "",
        limit = "",
    } = body || {};
    const payload = {pillar, group, account, region, service};
    if (date) payload.date = date;
    if (severityindex) payload.severityindex = severityindex;
    if (limit) payload.limit = limit;

    return payload;
};

export const getWellArchitectedContent = createAsyncThunk(
    "api/dashboard",
    async (body, thunkAPI) => {
        const payload = constructPayload(body);
        try {
            const response = await axiosInstance.post(
                `${baseUrlDashboard}/api/dashboard`,
                payload
            );
            return response.data.message;
        } catch (error) {
            console.error("error?.response", error?.response);
            // Structured error handling
            const errorMessage =
                error?.response?.data?.message ||
                "An unexpected error occurred";
            toast.error(errorMessage);
            return thunkAPI.rejectWithValue({errorMessage});
        }
    }
);

export const getWellArchitectedList = createAsyncThunk(
    "api/list",
    async (body, thunkAPI) => {
        const payload = constructPayload(body);

        try {
            const response = await axiosInstance.post(
                baseUrlDashboard + "/api/list",
                payload
            );

            return response.data.message;
        } catch (error) {
            console.log("error?.response", error?.response);
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const getWellArchitectedDates = createAsyncThunk(
    "api/date",
    async (body, thunkAPI) => {
        const payload = constructPayload(body);

        try {
            const response = await axiosInstance.post(
                baseUrlDashboard + "/api/date",
                payload
            );

            return response.data.message;
        } catch (error) {
            console.log("error?.response", error?.response);
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const getWellArchitectedItemDetailes = createAsyncThunk(
    "api/history",
    async (body, thunkAPI) => {
        // console.log("🚀 ~ body:", body);
        // const payload = constructPayload(body);

        try {
            const response = await axiosInstance.post(
                baseUrlDashboard + "/api/history",
                body
            );

            response.data.message.metrics.sort((a,b) => new Date(a.date) - new Date(b.date));

            return response.data.message;
        } catch (error) {
            console.log("error?.response", error?.response);
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const sendWellArchitectedReports = createAsyncThunk(
    "api/report",
    async (body, thunkAPI) => {
        console.log("body", body);
        try {
            const response = await axiosInstance.post(
                baseUrlDashboard + "/api/report",
                body
            );
            toast.success(response?.data?.message);
            return response.data.message;
        } catch (error) {
            console.log("error?.response", error?.response);
            toast.error(error?.response?.data?.message);
            return thunkAPI.rejectWithValue(error);
        }
    }
);
