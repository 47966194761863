import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {axiosInstance, baseUrlMain} from "../../config/api";

export const getLeftSideContent = createAsyncThunk(
    "/leftSideMenu/content",
    async () => {
        const response = await axiosInstance.post(baseUrlMain + "/api/menu", {});
        return response.data.message;
    }
);

export const leftSideMenuSlice = createSlice({
    name: "leftSideMenu",
    initialState: {
        isLoading: false,
        data: [],
    },
    reducers: {
        getLeftSide: (state, action) => {
            let {newDataObj} = action.payload;
            state.data = [...state.data, newDataObj];
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getLeftSideContent.pending, state => {
                state.isLoading = true;
            })
            .addCase(getLeftSideContent.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isLoading = false;
            })
            .addCase(getLeftSideContent.rejected, state => {
                state.isLoading = false;
            });
    },
});

export const {getLeftSide} = leftSideMenuSlice.actions;

export default leftSideMenuSlice.reducer;
