import {createAsyncThunk} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import {baseUrlCompany, baseUrlMain, axiosInstance} from "../../../config/api";


export const getAllCompanies = createAsyncThunk("api/companies", async (body, thunkAPI) => {
    console.log("body", body);
    try {
        const response = await axiosInstance.post(
            baseUrlCompany + "/api/companies",
            body
        );

        console.log("!!!!!!!!!!!!! response  !!!!!!!!!!!", response);
        toast.success(response?.data?.message);

        return response.data;
    } catch (error) {
        console.log("error?.response", error?.response);
        toast.error(error?.response?.data?.message);
        return thunkAPI.rejectWithValue(error);
    }
});

export const getChangeCompany = createAsyncThunk("api/change/company", async (body, thunkAPI) => {
    console.log("body", body);
    try {
        const response = await axiosInstance.post(
            baseUrlCompany + "/api/change/company",
            body
        );
        toast.success(response?.data?.message);

        console.log("!!!!!!!!!!!!! response  !!!!!!!!!!!", response);

        return response.data;
    } catch (error) {
        console.log("error?.response", error?.response);
        toast.error(error?.response?.data?.message);
        return thunkAPI.rejectWithValue(error);
    }
});

export const getCompanies = createAsyncThunk("api/get", async (body, thunkAPI) => {
    console.log("body", body);
    try {
        const response = await axiosInstance.post(
            baseUrlCompany + "/api/get",
            body
        );
        toast.success(response?.data?.message);
        console.log("!!!!!!!!!!!!! response  !!!!!!!!!!!", response);

        return response.data;
    } catch (error) {
        console.log("error?.response", error?.response);
        toast.error(error?.response?.data?.message);
        return thunkAPI.rejectWithValue(error);
    }
});

export const getChangeDescription = createAsyncThunk("api/description", async (body, thunkAPI) => {
    console.log("body", body);
    try {
        const response = await axiosInstance.post(
            baseUrlCompany + "/api/description",
            body
        );
        toast.success(response?.data?.message);

        console.log("!!!!!!!!!!!!! response  !!!!!!!!!!!", response);

        return response.data;
    } catch (error) {
        console.log("error?.response", error?.response);
        toast.error(error?.response?.data?.message);
        return thunkAPI.rejectWithValue(error);
    }
});

export const getNewGroup = createAsyncThunk("api/create", async (body, thunkAPI) => {
    console.log("body", body);
    try {
        const response = await axiosInstance.post(
            baseUrlCompany + "/api/create",
            body
        );

        console.log("!!!!!!!!!!!!! response  !!!!!!!!!!!", response);

        return response.data;
    } catch (error) {
        console.log("error?.response", error?.response);
        toast.error(error?.response?.data?.message);
        return thunkAPI.rejectWithValue(error);
    }
});

export const getRemoveGroup = createAsyncThunk("api/delete", async (body, thunkAPI) => {
    console.log("body", body);
    try {
        const response = await axiosInstance.post(
            baseUrlCompany + "/api/delete",
            body
        );
        toast.success(response?.data?.message);

        console.log("!!!!!!!!!!!!! response  !!!!!!!!!!!", response);

        return response.data;
    } catch (error) {
        console.log("error?.response", error?.response);
        toast.error(error?.response?.data?.message);
        return thunkAPI.rejectWithValue(error);
    }
});

export const getLogoUpdate = createAsyncThunk("api/logo/update", async (body, thunkAPI) => {
    console.log("body", body);
    try {
        const response = await axiosInstance.post(
            baseUrlMain + "/api/logo/update",
            body
        );
        toast.success(response?.data?.message);

        console.log("!!!!!!!!!!!!! response  !!!!!!!!!!!", response);

        return response.data;
    } catch (error) {
        console.log("error?.response", error?.response);
        toast.error(error?.response?.data?.message);
        return thunkAPI.rejectWithValue(error);
    }
});


